import React, { FC } from 'react';
import { Link } from 'gatsby';

import './SiteMapLink.scss';
import './ShadowSiteMapLink.scss';
import { IPropsSiteMapLinks } from './models';

const SiteMapLink: FC<IPropsSiteMapLinks> = ({ title, linksArray: { finalArrayOfLinks } }) => (
  <div data-test="SiteMapLink">
    <h2 className="sitemap-link__title">{title}</h2>
    {finalArrayOfLinks?.map(({ linkTitleMain, linkKids }, index) => (
      <ul key={`sitemap-link-ul-first-${index.toString()}`} className="sitemap-link">
        {linkTitleMain?.map(({ context: { link, pageName } }) => (
          <li key={link} className="sitemap-link__li">
            <Link to={link} className="sitemap-link__link">
              {pageName}
            </Link>
            {linkKids?.length ? (
              <ul
                key={`sitemap-link-ul-second-${pageName}`}
                className="sitemap-link sitemap-link--children"
              >
                {linkKids.map(
                  ({
                    linkKidsTitle: {
                      context: { link: linkKidsTitleLink, pageName: linkKidsTitlePageName },
                    },
                    preKids,
                  }) => (
                    <li
                      key={linkKidsTitleLink}
                      className="sitemap-link__li sitemap-link__li--children"
                    >
                      <Link to={linkKidsTitleLink} className="sitemap-link__link">
                        {linkKidsTitlePageName}
                      </Link>

                      {preKids ? (
                        <ul
                          key={`sitemap-link-ul-third-${linkKidsTitlePageName}`}
                          className="sitemap-link sitemap-link--pre-children"
                        >
                          {preKids.map(
                            ({ context: { link: preKidsLink, pageName: preKidsPageName } }) => (
                              <li
                                key={preKidsLink}
                                className="sitemap-link__li sitemap-link__li--pre-children"
                              >
                                <Link to={preKidsLink} className="sitemap-link__link">
                                  {preKidsPageName}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      ) : null}
                    </li>
                  )
                )}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    ))}
  </div>
);
export default SiteMapLink;
