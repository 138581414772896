import { UmbracoControls } from '@shared/types';
import { PAGE_TYPES } from '../../../scripts/constants';
import {
  IPageTypesFinal,
  IFilteredPagesArray,
  IPropsTransformArrayToArrayWithChildren,
  ILinksKids,
  IFinalArrayOfLinks,
  IPageTypes,
} from './models';

export const filteredPages = (
  pagesArray: IFilteredPagesArray[],
  excludedArray: UmbracoControls.MultiUrlPicker,
  lang: string
): IFilteredPagesArray[] =>
  pagesArray
    .filter((element) => element?.context?.link !== null && element?.context?.lang === lang)
    .filter((el) => excludedArray.every((item) => !item.url.includes(el.context.link)));

export const sortArray = (
  pages: IPropsTransformArrayToArrayWithChildren[]
): {
  linkTitle: IPropsTransformArrayToArrayWithChildren[];
  linkChild: IPropsTransformArrayToArrayWithChildren[];
  linkPreChild: IPropsTransformArrayToArrayWithChildren[];
} => {
  pages.sort((a, b) => (a.context.linkTransform.length > b.context.linkTransform.length ? 1 : -1));

  let minDigest: number = 0;
  let maxDigest: number = 0;

  if (pages.length >= 1) {
    if (pages[0].context.linkTransform.length) {
      minDigest = pages[0].context.linkTransform.length;
    }
    if (pages[pages.length - 1].context.linkTransform.length) {
      maxDigest = pages[pages.length - 1].context.linkTransform.length;
    }
  }

  const linkTitle: IPropsTransformArrayToArrayWithChildren[] = [];
  const linkChild: IPropsTransformArrayToArrayWithChildren[] = [];
  const linkPreChild: IPropsTransformArrayToArrayWithChildren[] = [];

  pages.forEach((page: IPropsTransformArrayToArrayWithChildren) => {
    if (page.context.linkTransform.length === minDigest) {
      linkTitle.push(page);
    } else if (page.context.linkTransform.length === maxDigest && maxDigest !== 2) {
      linkPreChild.push(page);
    } else {
      linkChild.push(page);
    }
  });

  return {
    linkTitle,
    linkChild,
    linkPreChild,
  };
};

export const transformArrayToArrayWithChildren = (
  pages: IFilteredPagesArray[]
): { finalArrayOfLinks: IFinalArrayOfLinks[] } => {
  const transformedUrlLinks = pages
    .filter((page: IFilteredPagesArray) => page.context.link !== 'object')
    .map((page: IFilteredPagesArray) => ({
      context: {
        ...page.context,
        linkTransform: page.context.link.split('/').filter((link, index) => {
          /**
           * 'linkTransform' should have the same structure for the foreign language
           * Exclude the language part from the url
           */
          if (!page.context.isMainLang && (index === 1 || index === 0)) {
            return false;
          }

          return link !== '';
        }),
      },
    }));

  const { linkTitle, linkChild, linkPreChild } = sortArray(transformedUrlLinks);

  const finalArrayOfLinks: IFinalArrayOfLinks[] = [];

  linkTitle.forEach((element) => {
    const linkTitleMain: IPropsTransformArrayToArrayWithChildren[] = [];
    const linkKids: ILinksKids[] = [];

    linkTitleMain.push(element);

    linkChild.forEach((item: IPropsTransformArrayToArrayWithChildren) => {
      const linkPreKids: IPropsTransformArrayToArrayWithChildren[] = [];

      if (item?.context?.linkTransform[0] === element.context.linkTransform[0]) {
        linkKids.push({ linkKidsTitle: item });
      }

      linkPreChild.forEach((child) => {
        if (
          item?.context?.linkTransform[1] === child?.context?.linkTransform[1] &&
          element.context.linkTransform[0] === child?.context?.linkTransform[0]
        ) {
          linkPreKids.push(child);
          linkKids[linkKids.length - 1] = { linkKidsTitle: item, preKids: linkPreKids };
        }
      });
    });

    finalArrayOfLinks.push({
      linkTitleMain,
      linkKids,
    });
  });

  return {
    finalArrayOfLinks,
  };
};

export const linksParser = (pages: IFilteredPagesArray[]): { finalPageLinks: IPageTypesFinal } => {
  const pageLinks: IPageTypes = {};

  Object.keys(PAGE_TYPES).forEach((el) => {
    pageLinks[el] = [];
  });

  pages.forEach((element: IFilteredPagesArray) => {
    if (element?.context?.pageType === PAGE_TYPES[element.context.pageType]) {
      pageLinks[element.context.pageType!].push(element);
    } else {
      pageLinks.remained.push(element);
    }
  });

  const finalPageLinks: IPageTypesFinal = {};

  Object.keys(pageLinks).forEach((key: string) => {
    finalPageLinks[key] = transformArrayToArrayWithChildren([...pageLinks[key]]);
  });

  return {
    finalPageLinks,
  };
};
